<template>
  <div class="loading">
    <a-spin />
  </div>
</template>

<script>
export default {
  name: 'Loading',
}
</script>

<style>
.loading {
  text-align: center;
  border-radius: 4px;
  padding: 30px 50px;
  margin: 20px 0;
  background-color: #5ecc8c48;
  margin-bottom: 20px;
}
</style>
