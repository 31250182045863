<template>
  <div>
    <tab-header>
      Subscription
      <template #text> Manages Subscription here </template>
    </tab-header>

    <a-modal
      title="Free Trial"
      :dialog-style="{ top: '20px' }"
      :visible="TrialModal"
      ok-text="Start Trial!"
      cancel-text="Later"
      @ok="subscribeToTrial"
      @cancel="TrialModal = false"
    >
      <div v-if="subscribingToTrial" class="flex items-center justify-center">
        <a-spin></a-spin>
      </div>

      <div v-else>
        <p>
          Hello {{ user.firstName }}, you are offered a
          <b
            >{{ TrialPlan.trialPeriod }}
            {{ TrialPlan.trialPeriodDuration }} free
          </b>
          trial would you like to activate your Trial?
        </p>

        <small
          >This trial subscribes you to the Directory free for
          {{ TrialPlan.trialPeriod }} {{ TrialPlan.trialPeriodDuration }}
        </small>
      </div>
    </a-modal>

    <paystack
      v-show="false"
      id="paystack"
      ref="paystack"
      :amount="Number(selectedTransaction.cost * 100)"
      :email="user.email"
      :paystackkey="paystackkey"
      :reference="
        initiatedTransaction.data ? initiatedTransaction.data.reference : ''
      "
      :callback="callback"
      :close="close"
      :embed="false"
    />

    <div v-if="loadingAvailableSubscription">
      <loading />
    </div>
    <div v-else class="md:flex items-start justify-start">
      <div class="md:w-8/12 mb-5 md:mb-0 md:mr-5">
        <div>
          <p>
            To subscribe via direct bank transfer, make payment to
            <b> Lawlexis International - Fidelity Bank - 4011176564. </b>
          </p>
          <p>
            Upon payment kindly send proof of payment to
            <a href="mailto:hello@legalnaija.com">hello@legalnaija.com</a> or
            via
            <a
              href="https://api.whatsapp.com/send?phone=+2349029755663 "
              target="_blank"
              >Whatsapp</a
            >
            to <a href="tel:+2349029755663">09029755663</a>.
          </p>
        </div>
        <div class="bg-white p-3 rounded-md shadow-md">
          <a-tabs
            v-model="activeKey"
            size="small"
            :style="{ width: '100%' }"
            default-active-key="1"
          >
            <a-tab-pane key="0" tab="Subscription Plan">
              <div class="grid md:grid-cols-2 grid-cols-1 gap-7">
                <div
                  v-for="availableSub in subscriptionPlans"
                  :key="availableSub._id"
                  :class="`bg-white rounded-md px-4 py-3 shadow-lg border-2 border-primary mb-2 ${
                    userPlans.findIndex(
                      (plan) =>
                        plan.plan !== null && plan.plan._id === availableSub._id
                    ) > -1
                      ? 'bg-green-100'
                      : ''
                  }`"
                >
                  <div class="flex justify-between items-baseline">
                    <h4 class="font-semibold text-lg mb-1 leading-none">
                      {{ availableSub.title }}
                    </h4>
                    <p class="text-gray-500 mt-2">
                      {{ availableSub.duration }}
                      {{ availableSub.durationPeriod }}
                    </p>
                  </div>
                  <p>{{ availableSub.description }}</p>
                  <h3 class="font-bold text-4xl mb-4">
                    ₦{{ numberWithCommas(availableSub.cost) }}
                  </h3>
                  <div class="flex items-center justify-end">
                    <a-button
                      v-if="availableSub.trialPeriod !== 0"
                      :loading="initiatingTransaction === availableSub._id"
                      :disabled="
                        (initiatingTransaction !== '' &&
                          initiatedTransaction !== availableSub._id) ||
                        userHasATrialPlan ||
                        userHasASubPlan
                      "
                      class="mr-4"
                      type="bordered"
                      @click="selectATrialPlan(availableSub)"
                      >Start Trial</a-button
                    >
                    <a-button
                      :loading="initiatingTransaction === availableSub._id"
                      :disabled="
                        (initiatingTransaction !== '' &&
                          initiatedTransaction !== availableSub._id) ||
                        userHasATrialPlan ||
                        userHasASubPlan
                      "
                      class=""
                      type="primary"
                      @click="initiateTransaction(availableSub)"
                      >Subscribe</a-button
                    >
                  </div>
                </div>
              </div>
            </a-tab-pane>
            <a-tab-pane key="1" tab="Listing Plan">
              <div class="grid md:grid-cols-2 grid-cols-1 gap-7">
                <template v-if="listingPlans.length === 0">
                  <h3 class="center">No listing plan available</h3>
                </template>
                <template v-else>
                  <div
                    v-for="availableSub in listingPlans"
                    :key="availableSub._id"
                    :class="`bg-white rounded-md px-4 py-3 shadow-lg border-2 border-primary mb-2 ${
                      userPlans.findIndex(
                        (plan) =>
                          plan.plan !== null &&
                          plan.plan._id === availableSub._id
                      ) > -1
                        ? 'bg-green-100'
                        : ''
                    }`"
                  >
                    <div class="flex justify-between items-baseline">
                      <h4 class="font-semibold text-lg mb-1 leading-none">
                        {{ availableSub.title }}
                      </h4>
                      <p class="text-gray-500 mt-2">
                        {{ availableSub.duration }}
                        {{ availableSub.durationPeriod }}
                      </p>
                    </div>
                    <p>{{ availableSub.description }}</p>
                    <h3 class="font-bold text-4xl mb-4">
                      ₦{{ numberWithCommas(availableSub.cost) }}
                    </h3>
                    <div class="flex items-center justify-end">
                      <a-button
                        v-if="availableSub.trialPeriod !== 0"
                        :loading="initiatingTransaction === availableSub._id"
                        :disabled="
                          initiatingTransaction !== '' &&
                          initiatedTransaction !== availableSub._id
                        "
                        class="mr-4"
                        type="bordered"
                        @click="selectATrialPlan(availableSub)"
                        >Start Trial</a-button
                      >
                      <a-button
                        :loading="initiatingTransaction === availableSub._id"
                        :disabled="
                          initiatingTransaction !== '' &&
                          initiatedTransaction !== availableSub._id
                        "
                        class=""
                        type="primary"
                        @click="initiateTransaction(availableSub)"
                        >Subscribe</a-button
                      >
                    </div>
                  </div>
                </template>
              </div>
            </a-tab-pane>
          </a-tabs>
        </div>
      </div>
      <div class="md:w-4/12">
        <div class="bg-white rounded shadow-md p-4">
          <h3 class="text-lg font-semibold mb-0">Active Plans</h3>
          <p>Details about all your plans</p>
          <div class="mt-3">
            <ul class="list-disc list-inside">
              <li v-for="plan in userPlans" :key="plan.id" class="my-2 text-sm">
                <template v-if="plan.status === 'active' && plan.plan !== null">
                  <span
                    v-if="plan.type === 'listing' && plan.status === 'active'"
                  >
                    You have a active
                    <span class="text-primary-dark font-semibold"
                      >{{ plan.plan.listingCategory }}
                      {{ plan.type }} plan</span
                    >
                    ,which started on
                    <span class="text-primary-dark font-semibold">
                      {{ new Date(plan.startDate).toDateString() }}
                    </span>
                    spanning
                    <span class="text-primary-dark font-semibold"
                      >{{ plan.plan.duration }}
                      {{ plan.plan.durationPeriod }}
                    </span>
                    and will expire on
                    <span class="text-primary-dark font-semibold">
                      {{ new Date(plan.endDate).toDateString() }}
                    </span>
                  </span>
                  <span
                    v-if="
                      plan.type === 'subscription' && plan.status === 'active'
                    "
                  >
                    You have an active subscription plan, due to expire on
                    <span class="font-semibold text-primary-dark">
                      {{ new Date(plan.endDate).toDateString() }}
                    </span>
                  </span>
                </template>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import TabHeader from '../../components/typography/TabHeaders.vue'
import subscriptionApi from '../../api/subscription'
import lawyerSubscriptionApi from '../../api/lawyers/subscription'
import Loading from '../../components/loading.vue'
import { numberWithCommas } from '../../utils/helpers'
import transactionApi from '../../api/transactions'

export default {
  name: 'LawyerSubscription',
  components: { TabHeader, Loading },
  layout: 'lawyerDashboard',
  data() {
    return {
      activeKey: 1,
      paystackkey: process.env.VUE_APP_PAYSTACK_KEY,
      loadingAvailableSubscription: false,
      numberWithCommas,
      availableSubscriptions: [],
      initiatingTransaction: '',
      userPlans: [],
      initiatedTransaction: {},
      currentPlan: {},
      TrialPlan: {},
      selectedTransaction: {
        cost: 0,
      },
      TrialModal: false,
      subscribingToTrial: false,
      paystackData: {
        amount: '',
        full_name: '',
        email: '',
      },
    }
  },
  head: {
    // script: [{ hid: 'paystack', src: 'https://js.paystack.co/v1/inline.js' }],
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
    subscriptionPlans() {
      return this.availableSubscriptions.filter(
        (plan) => plan.type === 'subscription'
      )
    },
    listingPlans() {
      return this.availableSubscriptions.filter(
        (plan) => plan.type === 'listing'
      )
    },
    userHasASubPlan() {
      return (
        this.userPlans.findIndex(
          (plan) => plan.status === 'active' && plan.type === 'subscription'
        ) > -1
      )
    },
    userHasAListingPlan() {
      return (
        this.userPlans.findIndex(
          (plan) => plan.status === 'active' && plan.type === 'listing'
        ) > -1
      )
    },
    userHasATrialPlan() {
      return this.userPlans.findIndex((plan) => plan.isTrial) > -1
    },
  },
  created() {
    this.getSubscriptions()
    this.getUserPlan()
    this.activeKey = this.$route.query.tab === 'subscription' ? '0' : '1'
  },
  mounted() {},
  methods: {
    selectATrialPlan(plan) {
      this.TrialPlan = plan
      this.TrialModal = true
    },
    async subscribeToTrial() {
      this.subscribingToTrial = false
      const req = await subscriptionApi(this.axios).trialPlan({
        planId: this.TrialPlan._id,
      })
      if (req.error) {
        this.$notification.error({
          message: 'Failed',
          description: req.message,
        })
        this.TrialModal = false
        this.subscribingToTrial = false
        return
      }
      this.$notification.success({
        message: 'Successful',
        description: req.message,
      })
      this.subscribingToTrial = false
      this.TrialModal = false
      this.getUserPlan()
    },
    async initiateTransaction(transaction) {
      this.initiatingTransaction = transaction._id
      this.selectedTransaction = transaction
      const req = await transactionApi(this.axios).initiate({
        email: this.user.email,
        firstName: this.user.firstName,
        lastName: this.user.lastName,
        category: 'subscription',
        categoryId: transaction._id,
        amount: transaction.cost,
      })
      if (req.error) {
        this.$notification.error({
          message: 'Unable to make payment',
          description: req.message,
        })
        this.initiatingTransaction = ''
        return
      }
      this.initiatedTransaction = req.data
      document.getElementById('paystack').click()
    },
    async verifyTransaction(reference) {
      const req = await transactionApi(this.axios).verify({
        reference,
      })
      if (req.error) {
        this.$notification.error({
          message: 'Transaction payment failed',
          description: req.message,
        })
        this.initiatingTransaction = ''
        return
      }
      this.$notification.info({
        message: 'Payment Successful',
        description: req.message,
      })
      this.$notification.success({
        message: 'Subscription Successful',
        description: req.message,
      })
      this.getUserPlan()
      this.initiatingTransaction = ''
    },
    async getSubscriptions() {
      this.loadingAvailableSubscription = true
      const req = await subscriptionApi(this.axios).getPlans()
      this.availableSubscriptions = req.data.data
      this.loadingAvailableSubscription = false
    },
    async getUserPlan() {
      const req = await lawyerSubscriptionApi(this.axios).getCurrentUserPlan({
        params: {
          populate: 'plan',
        },
      })
      this.userPlans = req.data.data
      // this.currentPlan = req.data
    },
    callback(response) {
      if (
        response.message.toLowerCase() === 'approved' ||
        response.message.toLowerCase() === 'success' ||
        response.state.toLowerCase() === 'success'
      ) {
        this.$notification.success({
          message: 'Subscription Successful',
          description: 'Your payment is successful',
        })
        this.verifyTransaction(response.reference)
      } else {
        this.$notification.error({
          message: 'Payment Failed',
          description: 'Unable to make payment, please try again',
        })
      }
    },
    close() {
      this.$notification.error({
        message: 'Subscription cancel',
        description: 'You cancelled your payment',
      })
      this.initiatingTransaction = ''
    },
  },
}
</script>
