import { errorResponse } from './response'

const transactionApi = ($axios) => {
  const http = $axios
  return {
    // days, months, weeks, years
    async initiate({
      email,
      firstName,
      lastName,
      category,
      categoryId,
      amount,
      variables = [],
      params,
    }) {
      try {
        const payload = {
          email,
          firstName,
          lastName,
          categoryId,
          category,
          variables,
          amount: Number(amount),
        }
        const req = await http.post('/transactions', payload, {
          params,
        })
        return req
      } catch (error) {
        return errorResponse(error)
      }
    },
    async verify({ reference, params }) {
      try {
        const req = await http.post(
          '/transactions/verify',
          { reference },
          {
            params,
          }
        )
        return req
      } catch (error) {
        return errorResponse(error)
      }
    },
    async getTransactions() {
      try {
        const req = await http.get(`/transactions`)
        return req
      } catch (error) {
        return errorResponse(error)
      }
    },
    async getTransaction({ transactionId }) {
      try {
        const req = await http.put(`/transactions/${transactionId}`)
        return req
      } catch (error) {
        return errorResponse(error)
      }
    },
  }
}

export default transactionApi
