import { errorResponse } from './response'

const subscriptionApi = ($axios) => {
  const http = $axios
  return {
    // days, months, weeks, years
    async create({
      title,
      cost,
      duration,
      durationPeriod,
      type,
      listingCategory,
      trialPeriod,
      trialPeriodDuration,
      description,
    }) {
      try {
        const payload = {
          title,
          cost,
          duration,
          durationPeriod,
          type,
          listingCategory,
          trialPeriod,
          trialPeriodDuration,
          description,
        }
        const req = await http.post('/plans', payload)
        return req
      } catch (error) {
        return errorResponse(error)
      }
    },
    async getPlans() {
      try {
        const req = await http.get('/plans')
        return req
      } catch (error) {
        return errorResponse(error)
      }
    },
    async getPlan({ planId }) {
      try {
        const req = await http.get(`/plans/${planId}`)
        return req
      } catch (error) {
        return errorResponse(error)
      }
    },
    async updatePlan({ planId, data }) {
      try {
        const req = await http.put(`/plans/${planId}`, data)
        return req
      } catch (error) {
        return errorResponse(error)
      }
    },
    async deletePlan({ planId }) {
      try {
        const req = await http.delete(`/plans/${planId}`)
        return req
      } catch (error) {
        return errorResponse(error)
      }
    },
    // Manual
    async subscribeAUser({ planId, userId }) {
      try {
        const req = await http.post(`/plans/subscribe-user`, {
          planId,
          userId,
        })
        return req
      } catch (error) {
        return errorResponse(error)
      }
    },
    async trialPlan({ planId }) {
      try {
        const req = await http.get(`/plans/${planId}/subscribe-to-trial`)
        return req
      } catch (error) {
        return errorResponse(error)
      }
    },
  }
}

export default subscriptionApi
