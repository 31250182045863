import { errorResponse } from '../response'

const subscriptionApiUser = ($axios) => {
  const http = $axios
  return {
    async getPlans() {
      try {
        const req = await http.get('/plans/subscriptions')
        return req
      } catch (error) {
        return errorResponse(error)
      }
    },
    async getPlan({ subscriptionId }) {
      try {
        const req = await http.get(`/plans/subscriptions/${subscriptionId}`)
        return req
      } catch (error) {
        return errorResponse(error)
      }
    },
    async getCurrentUserPlan({ params }) {
      try {
        const req = await http.get(`/plans/get-current-subscription`, {
          params,
        })
        return req
      } catch (error) {
        return errorResponse(error)
      }
    },
  }
}

export default subscriptionApiUser
